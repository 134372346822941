import React, { useState, useEffect } from 'react'
import {
    ImageList,
    ImageListItem,
    ImageListItemBar,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Checkbox,
    ListItemText,
} from '@mui/material'
import FadeInImage from '../FadeInImage'
import { useNavigate } from 'react-router-dom'
import CornerRibbon from "react-corner-ribbon"
import { projects } from '../../pages/Frontpage'

const ProjectGrid = ({ projects }) => {
    const navigate = useNavigate()
    const [sortBy, setSortBy] = useState('time')
    const [order, setOrder] = useState(0)
    const [categories, setCategories] = useState([])
    const [existingCategories, setExistingCategories] = useState([])
    useEffect(() => {
        const ec = new Set()
        projects.forEach(project => {
            project.categories.forEach(category => {
                ec.add(category)
            })
        })
        setExistingCategories(Array.from(ec))
        setCategories(Array.from(ec))
    }, [])

    return (
        <>
            <div className="mb-2 space-x-4 w-full h-16">
                <FormControl className="float-start">
                    <InputLabel id="sorting-by" className="!text-gray-300">Sorter Etter</InputLabel>
                    <Select
                        labelId="sorting-by"
                        defaultValue={'time'}
                        value={sortBy}
                        onChange={(event) => setSortBy(event.target.value)}
                        className="w-24 !rounded-xl !text-white"
                        style={{ backgroundColor: 'rgba(34, 139, 34, 0.4)' }}
                    >
                        <MenuItem value={'time'}>Tid</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                </FormControl>
                <FormControl className="float-start">
                    <InputLabel id="order" className="!text-gray-300">Rekkefølge</InputLabel>
                    <Select
                        labelId="order"
                        defaultValue={0}
                        value={order}
                        onChange={(event) => setOrder(event.target.value)}
                        className="w-30 !rounded-xl !text-white"
                        style={{ backgroundColor: 'rgba(34, 139, 34, 0.4)' }}
                    >
                        <MenuItem value={0}>Minkende</MenuItem>
                        <MenuItem value={1}>Stigende</MenuItem>
                    </Select>
                </FormControl>
                <FormControl className="float-start">
                    <InputLabel id="categories" className="!text-gray-300">Kategorier</InputLabel>
                    <Select
                        labelId="categories"
                        multiple
                        value={categories}
                        onChange={(event) => {
                            const {
                                target: { value },
                            } = event
                            setCategories(typeof value === 'string' ? value.split(',') : value)
                        }}
                        input={<OutlinedInput label="Tag" />}
                        renderValue={(selected) => selected.join(', ')}
                        className="w-40 !text-white text-left"
                        style={{ backgroundColor: 'rgba(34, 139, 34, 0.4)' }}
                    >
                        {existingCategories.map((category) => (
                            <MenuItem key={category} value={category}>
                                <Checkbox checked={categories.indexOf(category) > -1} />
                                <ListItemText primary={category} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <ImageList className="full-width overflow-hidden mb-4" cols={3} gap={12}>
                {projects.filter(project => project.categories.some(category => categories.includes(category))).map((project) => (
                    <ImageListItem key={project.name} className="overflow-hidden cursor-pointer relative" onClick={() => navigate(`/prosjekt/${project.id}`)}>
                        <CornerRibbon>
                            {project.semestra}
                        </CornerRibbon>
                        <FadeInImage src={project.image} alt={project.name} />
                        <ImageListItemBar
                            title={project.name}
                        />
                    </ImageListItem>
                ))}
            </ImageList>
        </>
    )
}
export default ProjectGrid