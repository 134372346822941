import hill5 from '../../resources/hill5.png'
import { useParallax, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax'

export const Hill5 = () => {
    const parallax = useParallax({
        translateX: [0, 25],
        startScroll: 0,
        endScroll: 600,
    })

    return (
        <div
            style={{
                aspectRatio: '2 / 1',
                top: 0,
                left: 0,
                overflow: 'hidden',
                width: '100%',
            }}
            className="!absolute"
            ref={parallax.ref}
        >
            <div
                style={{
                    backgroundImage: `url(${hill5})`,
                    backgroundPosition: 'center center',
                    backgroundSize: 'cover',
                    width: '100%',
                    height: '100%',
                }}
            />
        </div>
    )
}