import React from 'react'
import {
    Container,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    Link,
    Typography,
} from '@mui/material'
import EmailIcon from '@mui/icons-material/Email'
import CallIcon from '@mui/icons-material/Call'
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export const Contact = ({ darkMode }) => {
    return (
        <Container maxWidth="xl">
            <div className="flex flex-row pt-2">
                <img
                    src="https://scontent.fbgo1-1.fna.fbcdn.net/v/t39.30808-6/281555731_1680269925676094_2002585981853199886_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=5f2048&_nc_ohc=SN3hPpIfqZwQ7kNvgFSRHv_&_nc_ht=scontent.fbgo1-1.fna&oh=00_AYBFRYmj40WX2tVrsAEylwix1yJmb0HhBEX1E_QUE1MeLg&oe=665E43A7"
                    alt="Meg"
                    className="h-96 w-96 rounded-3xl"
                />

                <List className={darkMode ? '!text-white !bg-transparent' : '!text-black !bg-transparent'}>
                    <Link href="mailto:sofiebjarko@hotmail.com" underline="none">
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <EmailIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={<Typography variant="body1" className={darkMode ? 'text-white' : 'text-black'}>Email</Typography>}
                                secondary={<Typography variant="body2" className={darkMode ? 'text-gray-300' : 'text-gray-500'}>sofiebjarko@hotmail.com</Typography>}
                            />
                        </ListItem>
                    </Link>
                    <Link href="https://www.linkedin.com/in/sofie-bjarkø-072517281/" underline="none" target="_blank">
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <LinkedInIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={<Typography variant="body1" className={darkMode ? 'text-white' : 'text-black'}>LinkedIn</Typography>}
                                secondary={<Typography variant="body2" className={darkMode ? 'text-gray-300' : 'text-gray-500'}>Sofie Bjarkø</Typography>}
                            />
                        </ListItem>
                    </Link>
                </List>
            </div>
        </Container>
    )
}