import React from 'react'
import { Typography } from '@mui/material'

const parseDescription = (description) => {
    const paragraphs = description.split('\n\n') // Split by double newline to identify paragraphs
    const elements = paragraphs.map((paragraph, index) => {
        const lines = paragraph.split('\n') // Split each paragraph into lines

        const paragraphElements = lines.map((line, lineIndex) => {
            // Handle image
            const imageMatch = line.match(/!\[(.*?)\]\((.*?)\s*=\s*(\d*)\s*=\s*(left|right|center)?\)/)
            if (imageMatch) {
                const alt = imageMatch[1]
                const src = imageMatch[2]
                const id = alt.toLowerCase().replace(/\s+/g, '-')
                const width = imageMatch[3] || '100%'
                const float = imageMatch[4] || 'left' // Default to 'left' if no float is specified
                return (
                    <figure
                        key={index}
                        style={{
                            float: float,
                            paddingLeft: float === 'left' ? 0 : '1rem',
                            paddingRight: float === 'left' ? '1rem' : 0
                        }}
                        className="text-center w-fit"
                    >
                        <img src={src} alt={alt} className="w-full h-auto" style={{ maxWidth: `${width}px` }} />
                        <figcaption className="italic mt-1 text-start">{alt}</figcaption>
                    </figure>
                )
            } else {
                // Handle inline links to IDs
                const linkRegex = /\[(.*?)\]\(#(.*?)\)/g
                let lastIndex = 0
                const parts = []
                let match
                while ((match = linkRegex.exec(line)) !== null) {
                    const linkText = match[1]
                    const id = match[2]
                    const before = line.substring(lastIndex, match.index)
                    parts.push(<span key={`${lineIndex}-${lastIndex}`}>{before}</span>)
                    parts.push(<a key={`${lineIndex}-${match.index}`} href={`#${id}`} className="text-blue-500 hover:underline">{linkText}</a>)
                    lastIndex = match.index + match[0].length
                }
                if (lastIndex < line.length) {
                    parts.push(<span key={`${lineIndex}-${lastIndex}`}>{line.substring(lastIndex)}</span>)
                }
                return (
                    <Typography key={lineIndex} className="project-description" /*Optional: indent-5*/ paragraph>
                        {parts}
                    </Typography>
                )
            }
        })

        return (
            <div key={index} className="text-start text-white project-description">
                {paragraphElements}
            </div>
        )
    })

    return elements
}
export default parseDescription