import React, { useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import {
    Switch,
    theme,
    FormControlLabel,
    styled,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { ThemeProvider, createTheme } from '@mui/material'

const pages = ['Om Meg', 'Kontakt']

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                    '#fff',
                )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
        width: 32,
        height: 32,
        '&::before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                '#fff',
            )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}))

function ResponsiveAppBar({ darkMode, setDarkMode }) {
    const [cookies, setCookies] = useCookies(['darkMode'])

    const [anchorElNav, setAnchorElNav] = useState(null)
    const [anchorElUser, setAnchorElUser] = useState(null)

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget)
    }
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null)
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                smm: 350,
                smp: 420,
                sm: 600,
                md: 960,
                lg: 1280,
                xl: 1920,
            },
        },
    })

    return (
        <AppBar position="fixed" className={darkMode ? "!bg-black !text-white !shadow-none" : "!bg-white !text-black !shadow-none"}>
            <ThemeProvider theme={theme}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        {/* Navbar Logo */}
                        <Link to={`/`} draggable={false}>
                            <Typography
                                variant="h6"
                                noWrap
                                sx={{
                                    mr: 2,
                                    display: { xs: 'none', smp: 'flex' },
                                    fontFamily: 'monospace',
                                    fontWeight: 700,
                                    letterSpacing: '.3rem',
                                    textDecoration: 'none',
                                    color: 'inherit',
                                }}
                            >
                                Sofie
                            </Typography>
                        </Link>

                        {/* Navbar Content */}
                        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'end' }}>
                            <Box sx={{ display: { xs: 'flex', smm: 'none' }, flexGrow: 1 }}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color="inherit"
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}
                                    sx={{
                                        display: { xs: 'block', smm: 'none' },
                                    }}
                                >
                                    {pages.map((page) => (
                                        <MenuItem key={page} onClick={handleCloseNavMenu}>
                                            <Typography textAlign="center">{page}</Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>
                            <Box sx={{ display: { xs: 'none', smm: 'flex' } }}>
                                {pages.map((page, index) => (
                                    <Link to={`/${page.toLowerCase().replace(' ', '-')}`} draggable={false} key={index}>
                                        <Button
                                            className="!rounded-3xl nav-font !transition-all !duration-300 !ease-in-out !font-semibold !normal-case"
                                            sx={{ my: 2, mx: 0.5, display: 'block' }}
                                        >
                                            {page}
                                        </Button>
                                    </Link>
                                ))}
                            </Box>

                            {/* Darkmode controls */}
                            <Box sx={{ display: { xs: 'none', smm: 'flex' } }}>
                                <FormControlLabel
                                    control={<MaterialUISwitch sx={{ m: 1 }} checked={darkMode} onChange={(event) => {
                                        setCookies("darkMode", event.target.checked)
                                        setDarkMode(event.target.checked)
                                    }} />}
                                />
                            </Box>
                        </Box>
                    </Toolbar>
                </Container>
            </ThemeProvider>
        </AppBar>
    )
}
export default ResponsiveAppBar