import React, { useState, useEffect } from 'react'
import {
    ImageList,
    ImageListItem,
    Container,
    ImageListItemBar,
    IconButton,
} from '@mui/material'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer, useParallax } from 'react-scroll-parallax'
import { useLocation } from 'react-router-dom'

import FadeInImage from '../components/FadeInImage'
import ProjectGrid from '../components/Projects/ProjectGrid'
import { scrollToSection } from '../utils/scroll'

import hill1 from '../resources/hill1.png'
import hill2 from '../resources/hill2.png'
import hill3 from '../resources/hill3.png'
import plant from '../resources/plant.png'
import tree from '../resources/tree.png'
import { Leaf } from './parallax/leaf'
import { Hill4 } from './parallax/hill4'
import { Hill5 } from './parallax/hill5'

export const projects = [
    {
        id: 'refstadparken',
        name: 'Refstadparken - Fremtidig visjon for Oslos parksystemer',
        location: 'Økern, Oslo',
        year: 'Vår 2024',
        image: 'https://www.fjordtravel.no/wp-content/uploads/2013/09/UNESCO-Naeroyfjord-by-Terese-Kvinge.jpg',
        short: 'Refstadparken...',
        description: `Vestibulum vel hendrerit est. Vivamus vehicula enim elit, sit amet scelerisque ante iaculis ac. Donec accumsan neque ac dolor ullamcorper tempus. Nullam et dolor a nulla auctor mattis et sit amet dui. Pellentesque eget maximus justo. Cras at maximus turpis. Duis fermentum tincidunt sapien, ac convallis sapien auctor a. Donec vitae maximus arcu. Donec ornare velit eget diam interdum, eu molestie odio commodo. Integer efficitur rhoncus ligula, nec fermentum mi viverra eget. Vivamus id egestas urna, quis mollis lacus. Donec pellentesque nunc ante, vitae volutpat purus tincidunt quis. Quisque molestie sagittis nisl vel scelerisque. Quisque sodales a nisi non faucibus. Duis ultrices cursus diam, eget fermentum ex vehicula in. Nullam ipsum eros, ultricies fermentum metus sed, rhoncus rhoncus sem.
        ![Refstad Park](https://www.fjordtravel.no/wp-content/uploads/2013/09/UNESCO-Naeroyfjord-by-Terese-Kvinge.jpg =300=left)
        [Read more about Birkelunden](#birkelunden-park) Ut viverra felis ligula. Maecenas eget neque et ante tristique congue ac id velit. Nam magna magna, ultricies sit amet hendrerit non, vestibulum eget justo. Mauris sed augue sit amet justo eleifend fermentum ac ac massa. Mauris elementum sem ante, in eleifend velit rhoncus ac. Integer ac porttitor mi, ac placerat sapien. Aenean luctus egestas turpis sit amet faucibus. Aenean eleifend leo ut aliquam congue. Donec volutpat hendrerit diam, quis iaculis elit lacinia a. Nulla at purus id enim aliquet feugiat quis eu arcu. Sed ullamcorper ullamcorper massa, accumsan bibendum elit consequat eget. Praesent dignissim laoreet ex, vitae consectetur lorem placerat nec. Vestibulum iaculis aliquet nisi, quis egestas nibh pellentesque in. Curabitur elit tellus, vehicula at odio in, aliquam congue felis. Phasellus posuere vitae odio ut gravida.
        Donec fermentum dui quis mauris maximus luctus. Proin non nunc ex. Duis vehicula purus in erat iaculis, non aliquet purus porttitor. Curabitur sagittis vestibulum leo, malesuada aliquam nunc faucibus nec. Morbi scelerisque fringilla lobortis. Cras massa tellus, pharetra sit amet neque at, dictum dignissim sem. Vivamus id nisl orci. Praesent commodo, magna vel gravida volutpat, est sapien malesuada nulla, quis rutrum urna lorem pulvinar ante. Nulla facilisi.
        Praesent fermentum justo id turpis eleifend tristique. Mauris convallis venenatis velit, at tincidunt leo iaculis nec. Maecenas elit leo, commodo non imperdiet in, placerat id dolor. Mauris euismod, nunc ut eleifend fringilla, mauris purus dignissim lacus, a ultricies nunc augue a nunc. Suspendisse volutpat ante a eros viverra laoreet hendrerit eu nibh. Nam volutpat hendrerit turpis, id bibendum justo placerat sed. Nulla vulputate auctor est eu sagittis.
        `,
        semestra: '1 Semester',
        categories: ['Park', 'Økologi'],
    },
    {
        id: 'birkelunden',
        name: 'Birkelunden - Mønster i byparken',
        location: 'Grünerløkka, Oslo',
        year: 'Vår 2023',
        image: 'https://www.fjordtravel.no/wp-content/uploads/2013/09/UNESCO-Naeroyfjord-by-Terese-Kvinge.jpg',
        short: 'Birkelunden...',
        description: `Vestibulum vel hendrerit est. Vivamus vehicula enim elit, sit amet scelerisque ante iaculis ac. Donec accumsan neque ac dolor ullamcorper tempus. Nullam et dolor a nulla auctor mattis et sit amet dui. Pellentesque eget maximus justo. Cras at maximus turpis. Duis fermentum tincidunt sapien, ac convallis sapien auctor a. Donec vitae maximus arcu. Donec ornare velit eget diam interdum, eu molestie odio commodo. Integer efficitur rhoncus ligula, nec fermentum mi viverra eget. Vivamus id egestas urna, quis mollis lacus. Donec pellentesque nunc ante, vitae volutpat purus tincidunt quis. Quisque molestie sagittis nisl vel scelerisque. Quisque sodales a nisi non faucibus. Duis ultrices cursus diam, eget fermentum ex vehicula in. Nullam ipsum eros, ultricies fermentum metus sed, rhoncus rhoncus sem.
        ![Birkelunden Park](https://www.fjordtravel.no/wp-content/uploads/2013/09/UNESCO-Naeroyfjord-by-Terese-Kvinge.jpg =300=left)
        [Read more about Birkelunden](#birkelunden-park) Ut viverra felis ligula. Maecenas eget neque et ante tristique congue ac id velit. Nam magna magna, ultricies sit amet hendrerit non, vestibulum eget justo. Mauris sed augue sit amet justo eleifend fermentum ac ac massa. Mauris elementum sem ante, in eleifend velit rhoncus ac. Integer ac porttitor mi, ac placerat sapien. Aenean luctus egestas turpis sit amet faucibus. Aenean eleifend leo ut aliquam congue. Donec volutpat hendrerit diam, quis iaculis elit lacinia a. Nulla at purus id enim aliquet feugiat quis eu arcu. Sed ullamcorper ullamcorper massa, accumsan bibendum elit consequat eget. Praesent dignissim laoreet ex, vitae consectetur lorem placerat nec. Vestibulum iaculis aliquet nisi, quis egestas nibh pellentesque in. Curabitur elit tellus, vehicula at odio in, aliquam congue felis. Phasellus posuere vitae odio ut gravida.
        Donec fermentum dui quis mauris maximus luctus. Proin non nunc ex. Duis vehicula purus in erat iaculis, non aliquet purus porttitor. Curabitur sagittis vestibulum leo, malesuada aliquam nunc faucibus nec. Morbi scelerisque fringilla lobortis. Cras massa tellus, pharetra sit amet neque at, dictum dignissim sem. Vivamus id nisl orci. Praesent commodo, magna vel gravida volutpat, est sapien malesuada nulla, quis rutrum urna lorem pulvinar ante. Nulla facilisi.
        Praesent fermentum justo id turpis eleifend tristique. Mauris convallis venenatis velit, at tincidunt leo iaculis nec. Maecenas elit leo, commodo non imperdiet in, placerat id dolor. Mauris euismod, nunc ut eleifend fringilla, mauris purus dignissim lacus, a ultricies nunc augue a nunc. Suspendisse volutpat ante a eros viverra laoreet hendrerit eu nibh. Nam volutpat hendrerit turpis, id bibendum justo placerat sed. Nulla vulputate auctor est eu sagittis.
        `,
        semestra: '2 Semester',
        categories: ['Park'],
    },
]

export const Frontpage = ({ darkMode }) => {
    const location = useLocation()

    const [marginTop, setMarginTop] = useState(0)
    const [screenWidth, setScreenWidth] = useState(0)
    useEffect(() => {
        const updateMarginTop = () => {
            const screenWidth = window.innerWidth
            setScreenWidth(screenWidth)
            setMarginTop(screenWidth > 600 ? screenWidth / 2 - 80 : screenWidth / 2)
        }

        updateMarginTop()
        window.addEventListener('resize', updateMarginTop)

        return () => {
            window.removeEventListener('resize', updateMarginTop)
        }
    }, [])


    useEffect(() => {
        const handleLoad = () => {
            if (location.hash === '#projects') {
                const element = document.getElementById('projects')
                if (element) {
                    scrollToSection("projects")
                }
            }
        };

        window.addEventListener('load', handleLoad)

        return () => {
            window.removeEventListener('load', handleLoad)
        }
    }, [location.hash])

    return (
        <>
            <ParallaxProvider>
                <ParallaxBanner className="bg-white !absolute" style={{ aspectRatio: '2 / 1', top: 0, left: 0 }}>
                    <ParallaxBannerLayer image={hill1} speed={-30} />
                </ParallaxBanner>
                <ParallaxBanner className="!absolute" style={{ aspectRatio: '2 / 1', top: 0, left: 0 }}>
                    <ParallaxBannerLayer image={hill2} speed={-20} />
                </ParallaxBanner>
                <ParallaxBanner className="!absolute" style={{ aspectRatio: '2 / 1', top: 0, left: 0 }}>
                    <ParallaxBannerLayer image={hill3} speed={-10} />
                </ParallaxBanner>

                <Hill4 />
                <Hill5 />

                <ParallaxBanner className="!absolute" style={{ aspectRatio: '2 / 1', top: 0, left: 0 }}>
                    <ParallaxBannerLayer image={plant} speed={0} />
                </ParallaxBanner>
                <ParallaxBanner className="!absolute" style={{ aspectRatio: '2 / 1', top: 0, left: 0 }}>
                    <ParallaxBannerLayer image={tree} speed={0} />
                </ParallaxBanner>

                <ParallaxBanner
                    className="!absolute"
                    style={{ aspectRatio: '2 / 1', top: 0 }}
                >
                    <ParallaxBannerLayer
                        speed={screenWidth > 600 ? 8 : -6}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <h1
                            className="text-center text-white name-frontpage"
                            style={{
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                textShadow: '4px 4px 8px rgba(0,0,0,.4)'
                            }}
                        >
                            Sofie Bjarkø
                        </h1>
                    </ParallaxBannerLayer>
                </ParallaxBanner>
                <Leaf />
            </ParallaxProvider>
            <Container maxWidth="xl" style={{ marginTop: marginTop }}>
                <ArrowDownwardIcon
                    className="animate-bounce bg-bl rounded-full p-1.5 border-slate-500 border-2 border-solid shadow shadow-slate-50 cursor-pointer mb-8 mt-8"
                    sx={{ fontSize: 40, color: 'rgb(139 92 246)' }}
                    onClick={() => scrollToSection("projects")}
                    id="projects"
                />

                <ProjectGrid projects={projects || []} />
            </Container>
        </>
    )
}