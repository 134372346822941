import React, { useState, useEffect } from 'react'
import ResponsiveAppBar from './components/ResponsiveAppBar'
import './App.css'
import { Frontpage, AboutMe, Contact } from './pages'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import Project from './pages/Project'

function App() {
    document.title = 'Sofie Bjarkø'

    const [darkMode, setDarkMode] = useState(false)
    const [cookies, setCookies] = useCookies(['darkMode'])
    useEffect(() => {
        if (cookies.darkMode === 'undefined' || cookies.darkMode === undefined) setCookies('darkMode', false)
        else setDarkMode(cookies.darkMode === true)
    }, [])

    useEffect(() => {
        // document.body.style.backgroundColor = darkMode ? 'black' : 'white'
        document.body.style.backgroundColor = '#003329'
    }, [darkMode])

    return (
        <div className="App">
            <Router>
                <ResponsiveAppBar darkMode={darkMode} setDarkMode={setDarkMode} />
                <div style={{ marginTop: '70px' }}>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <Frontpage darkMode={darkMode} />
                            }
                        />
                        <Route
                            path="/om-meg"
                            element={
                                <AboutMe />
                            }
                        />
                        <Route path="/prosjekt/:id" element={<Project />} />
                        <Route
                            path="/kontakt"
                            element={
                                <Contact darkMode={darkMode} />
                            }
                        />
                    </Routes>
                </div>
            </Router>
        </div>
    )
}
export default App