import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {
    Container,
    Breadcrumbs,
    Link,
    Typography,
    ImageListItem,
    ImageListItemBar,
    List,
    ListItem,
} from '@mui/material'
import Carousel from 'react-multi-carousel'
import "react-multi-carousel/lib/styles.css"
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { useNavigate } from 'react-router-dom'

import parseDescription from '../components/Project/ParseDescription'
import { projects } from './Frontpage'
import { scrollToSection } from '../utils/scroll'

const Project = () => {
    const navigate = useNavigate()
    const { id } = useParams()

    const [project, setProject] = useState(null)

    useEffect(() => {
        const proj = projects.find(item => item.id === id)
        if (proj !== undefined) setProject(projects.find(item => item.id === id))
    }, [id])

    return (
        <Container maxWidth="xl">
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" className="text-white" />}>
                <Link underline="hover" href="/#projects" className="!text-gray-300">
                    Prosjekter
                </Link>
                <Typography className="text-white">{project ? project.name : '?'}</Typography>
            </Breadcrumbs>

            <Typography
                className="text-white"
                variant="h2"
                component="h2"
            >
                {project ? project.name : 'Ugyldig Prosjekt'}
            </Typography>

            {/* Table of Contents */}
            <List className="border-black !border-2 !w-fit !rounded !mb-2 float-start !mr-4" style={{ backgroundColor: 'rgba(34, 139, 34,.4)' }}>
                <ListItem
                    onClick={() => scrollToSection('')}
                    className="text-gray-300 hover:text-white cursor-pointer transition-colors duration-300 ease-in-out"
                >
                    Introduksjon
                </ListItem>
                <ListItem
                    onClick={() => scrollToSection('')}
                    className="text-gray-300 hover:text-white cursor-pointer transition-colors duration-300 ease-in-out"
                >
                    Prosjektbeskrivelse
                </ListItem>
                <ListItem
                    onClick={() => scrollToSection('andre-spennende-prosjekter')}
                    className="text-gray-300 hover:text-white cursor-pointer transition-colors duration-300 ease-in-out"
                >
                    Andre Spennende Prosjekter
                </ListItem>
            </List>

            {/* Project Description */}
            <div
                className="text-start text-white"
            >
                {project && parseDescription(project.description)}
            </div>

            {/* Other Projects */}
            <Typography className="text-start text-white !font-bold italic !text-xl" id="andre-spennende-prosjekter">
                Andre Spennende Prosjekter
            </Typography>
            <Carousel
                className="pt-4 pb-4 w-full m-auto"
                additionalTransfrom={0}
                arrows
                autoPlay={true}
                autoPlaySpeed={4500}
                centerMode={true}
                containerClass="container"
                draggable={false}
                focusOnSelect={false}
                infinite={true}
                pauseOnHover
                renderDotsOutside={false}
                rewind={true}
                rewindWithAnimation={false}
                rtl
                showDots={false}
                slidesToSlide={-1}
                swipeable={false}
                responsive={{
                    desktop: {
                        breakpoint: {
                            max: 3000,
                            min: 1024
                        },
                        items: 3,
                        partialVisibilityGutter: 40
                    },
                    mobile: {
                        breakpoint: {
                            max: 464,
                            min: 0
                        },
                        items: 1,
                        partialVisibilityGutter: 30
                    },
                    tablet: {
                        breakpoint: {
                            max: 1024,
                            min: 464
                        },
                        items: 2,
                        partialVisibilityGutter: 30
                    }
                }}
            >
                {projects.filter((item) => item.id !== id).map((project, index) => (
                    <ImageListItem key={index} className="overflow-hidden cursor-pointer ms-4" onClick={() => {
                        window.scrollTo(0, 0)
                        navigate(`/prosjekt/${project.id}`)
                    }}>
                        <img
                            src={project.image}
                            alt={project.name}
                        />
                        <ImageListItemBar
                            title={project.name}
                        />
                    </ImageListItem>
                ))}
            </Carousel>
        </Container>
    )
}
export default Project